<template>
  <div class="centered-wrapper container-wrapper">
    <Logo class="logo" />
    <FormWrapper>
      <h2 class="main-title">Авторизация по ЭЦП</h2>
      <h3 class="sub-title">Доступ к системе</h3>
      <hr class="u-mt--1 u-mb--1" />
      <Button width="100%" class="u-mb--1" @click.prevent="tokenAuth"> Войти </Button>

      <p v-if="error" class="red_text">{{ error }}</p>
      <Separator> </Separator>
      <div class="registration-link">
        <a href="/setup_client.exe"> Скачать клиент</a>
      </div>
      <Separator> или </Separator>
      <router-link :to="{ name: 'login' }" class="restoration-link link">
        Вход по паролю
      </router-link>

      <Separator> </Separator>
      <div class="registration-link">
        Еще не пользователь?
        <router-link :to="{ name: 'registration' }"> Регистрация </router-link>
      </div>
    </FormWrapper>
  </div>
</template>

<script>
// import { ref } from "vue";
import api from "@/core/api";
import { getUrl, isUrlExists } from "@/core/utils/returnUrlController";
import { auth } from "@/core/services/auth";

const authTokenDataMock = {
  // data: {
  //   // signature: "string",
  // },
};
import Separator from "@/core/components/Separator/Separator.vue";
import FormWrapper from "@/core/components/FormWrapper/FormWrapper.vue";
import Button from "@/core/components/Button/Button.vue";

import Logo from "@/assets/icons/logo.svg";
export default {
  components: { Button, FormWrapper, Logo, Separator },
  data() {
    return {
      socket: null,
      cb: null,
      in_state: null,
      error: null,
    };
  },
  methods: {
    tokenState: function () {
      console.log("tokenState");
      const request = {
        command: "token_state",
        callback: "qqq",
      };

      this.socket.send(JSON.stringify(request));
    },

    getPin: function () {
      // if( typeof $.cookie('token_pin') !== 'undefined'){
      //   if( $.cookie('token_pin') !== 'null') {
      //     let token_pin = $.cookie("token_pin");
      //     console.log("Cookie PIN:"+ token_pin );
      //     return token_pin;
      //   }
      // }
      //else
      let token_pin = prompt("Введите пинкод");
      if (token_pin) {
        return token_pin;
      }
      return undefined;
    },
    tokenAuth: function () {
      console.log("Log in");
      if (this.socket.readyState !== 1) {
        alert("Сервис по работе с ключами не найден. Нужно установить сервис работы с Ключами");
        // window.location.href = '/sso/service/'
        return;
      }

      let pin = this.getPin();

      if (pin) {
        const auth = {
          command: "login",
          parameters: {
            pin: pin,
          },
        };

        this.cb = (response) => {
          if (response.success) {
            // $.cookie("token_pin", pin, { path: "/" });
            this.tokenSignData("LoginMe", this.authByToken);
          }
        };

        this.socket.send(JSON.stringify(auth));
      }
    },
    redirectToBack: function (id) {
      const returnUrl = getUrl(id);
      window.open(returnUrl, "_self");
    },
    getToken: function (id) {
      api.getToken(id).then((resp) => {
        auth.setToken(resp.data.access_token);
        this.$router.push({ name: "profile" });
      });
    },
    authByToken: async function (response) {
      console.log(response.result);
      try {
        const tokenData = Object.assign({}, authTokenDataMock);
        tokenData.signature = response.result;
        const {
          data: { id },
        } = await api.getAuthTokenByCert(tokenData);
        if (isUrlExists()) {
          this.redirectToBack(id);
        } else {
          this.getToken(id);
        }
      } catch (e) {
        console.error(e);
      }
    },
    tokenSignData: function (param, callback) {
      this.in_state = "sign";

      const sign = {
        command: "sign",
        parameters: {
          message: param,
        },
      };

      this.cb = callback;
      this.socket.send(JSON.stringify(sign));
    },
    connectToServer: function () {
      this.socket = new WebSocket("ws://127.0.0.1:8090/");
      console.log("WebSocket Connecting...");
      this.socket.onopen = () => {
        console.log("WebSocket Connected OK");
        this.tokenState();
      };
      this.socket.onmessage = (event) => {
        let resp = JSON.parse(event.data);
        console.log(resp);
        console.log("WS Callback", this.cb);

        let callback = this.cb;
        this.cb = undefined;
        if (callback != null && typeof callback !== "undefined") {
          callback(resp);
        } else if (resp.generator_progress) {
          console.log(resp.generator_progress);
        } else if (resp.token_connected) {
          this.tokenAuth();
        } else if (resp.error === "such doctor does not exist") {
          console.log("Токен не зарегестрирован");
        } else if (!resp.token_connected) {
          alert("Нужно подключить USB Ключ Jakarta");
          // in_state = "";
          // $.cookie('token_pin',null, { path: '/' });
          // setTimeout(function(){
          //     if(is_authenticated) {
          //         window.location.href="/logout/";
          //     }
          // },200);
        }
      };
      this.socket.onclose = () => {
        console.log("WebSocket Disconnected");
        this.error = "Ошибка подключения к клиену ЭЦП, нужно запустить или установить клиент";
      };
    },
  },
  beforeUnmount() {
    console.log("close socket");
    if (this.socket && this.socket.readyState === 0) {
      this.socket.close;
    }
  },
  mounted() {
    this.connectToServer();
  },
  setup() {},
};
</script>

<style lang="scss" scoped src="./LoginToken.scss"></style>
